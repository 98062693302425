'use client'
import { setGADataLayer } from 'analytics'
import { useEffect, useState, useRef } from 'react'
import styles from './ads.module.css'

function useAds(): void {
    const [adsLoaded, setAdsLoaded] = useState<boolean>(false)
    useEffect(() => {
        if (!adsLoaded) {
            window.didomiOnReady = window.didomiOnReady || []
            window.didomiOnReady.push(() => {
                // Triggers "HTL CSS & JS" event in GTM
                setGADataLayer({ event: 'activate_ads' })
                setAdsLoaded(true)
            })
        }
    }, [adsLoaded])
}

export const TopLeaderboardAd = ({ deviceType }: { deviceType: string }) => {
    const unitClassName = `htlad-${deviceType}_top_leaderboard_media`
    useAds()

    return (
        <div className={styles.top_leaderboard_container}>
            <div className={styles.top_leaderboard_placeholder}>
                <div className={unitClassName}></div>
            </div>
        </div>
    )
}

export const SkyscraperAd = () => {
    const unitClassName = `htlad-desktop_skyscraper_media`

    return (
        <div className={styles.skyscraper_container}>
            <div className={unitClassName}></div>
        </div>
    )
}

export const MediumRectangleAd = () => {
    const unitClassName = `htlad-mobile_medium_rectangle_media`
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const elementRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const rect = elementRef?.current?.getBoundingClientRect()
        if (rect && window.visualViewport && window.visualViewport.height - rect.bottom >= 250) {
            setIsEnabled(true)
        }
    }, [elementRef])

    return (
        <div ref={elementRef}>
            {isEnabled && (
                <div className={styles.medium_rectangle_container}>
                    <div className={styles.medium_rectangle_placeholder}>
                        <div className={unitClassName}></div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MediumRectangleAd

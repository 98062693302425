'use client'

import { IGif } from '@giphy/js-types'
import { ReactNode } from 'react'
import { sendGTMEvent } from '@/app/util/gtm'

const Gif = ({ gif, children }: { gif: IGif; children: ReactNode }) => (
    <a
        href={gif.url}
        onClick={() => {
            sendGTMEvent({
                category: 'GIF Landing',
                action: 'Click',
                label: 'Image',
            })
        }}
    >
        {children}
    </a>
)

export default Gif
